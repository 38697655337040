<template>
    <div
        id="resident-detail-view-moyen-paiement"
        class="data-list-container"
    >
        <ResidentHeader page-title="Moyen de paiement" />
        <HBtn styleType="secondary" :to="linkToResidentProfil" class="mb-4">Retour au profil</HBtn>

        <div class="payment-detail" v-if="$aclChecker.some($acl, ['isTutor', 'isFamily', 'isHappytalSupport']) && mode=='update' && loaded">
            <div class="detail-header">
                <div class="header-title">Enregistrer votre moyen de paiement</div>
            </div>
                <div class="detail-content">
                    <div class="payment-label">
                        <!-- {{ (viewMode == 'setup' && isUserFamily ) ? "Pour valider vos abonnements, nous avons besoin d'enregistrer votre moyen de paiement afin de procéder aux commandes mensuelles." :''}} -->
                            <!-- {{ viewMode != 'oneshot' ? `Sélectionner un mode de paiement` : '' }} -->
                    </div>
                    <PaymentModeSelector
                        class="payment-selector"
                        :firstOption="isUserFamily ? 'card' : 'sepa_debit'"
                        :value="payment.mode.setup"
                        @change="onPaymentSetupModeChange"
                    />
                    <div class="payment-form">
                        <div class="form-label">Nom du titulaire</div>
                        <vs-input
                            autofocus
                            class="form-input input-name"
                            v-model="payment.name"
                            type="text"
                            placeholder="Titulaire du compte"
                            data-cy="name-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.name"
                        >{{ fieldErrors.name }}</div>
                        <div class="form-label">Email du titulaire</div>
                        <vs-input
                            autofocus
                            class="form-input input-email"
                            v-model="payment.email"
                            type="text"
                            placeholder="votre.email@mail.com"
                            data-cy="email-field"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.email"
                        >{{ fieldErrors.email }}</div>
                        <div class="field-iban" v-show="payment.mode.setup == 'sepa_debit'">
                            <div class="form-label">IBAN</div>
                            <div class="stripe-form-input input-iban iban-element"></div>
                            <div class="form-field-error" v-if="fieldErrors.iban">{{ fieldErrors.iban }}</div>
                        </div>
                        <div class="field-bic" v-if="0" v-show="payment.mode.setup == 'sepa_debit'">
                            <div class="form-label">BIC / SWIFT</div>
                            <vs-input class="form-input input-bic" v-model="payment.bic" type="text" placeholder="Numéro à XX chiffres"/>
                            <div class="form-field-error" v-if="fieldErrors.bic">{{ fieldErrors.bic }}</div>
                        </div>
                        <div class="field-card" v-show="payment.mode.setup == 'card'">
                            <div class="form-label">Carte de titulaire</div>
                            <div class="stripe-form-input input-card card-element"></div>
                            <div
                                class="form-field-error"
                                v-if="fieldErrors.card"
                            >{{ fieldErrors.card }}</div>
                        </div>
                        <div class="form-check mt-6">
                            <div class="check-input">
                                <vs-checkbox
                                    v-model="payment.save_consent"
                                >
                                    <div class="check-label">Afin de faciliter les futurs paiements, j'accepte que mes informations de paiement (Carte Bancaire ou Prélèvement SEPA) soient conservées, de façon sécurisée, et utilisées pour les prochains paiements.
                                    </div>
                                </vs-checkbox>
                            </div>

                    </div>
                    <div class="form-actions">
                        <vs-button class="save-btn" :disabled="saveBtnDisabled" @click="onSaveBtnClick">Enregistrer</vs-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment-detail" v-else-if="mode=='update' && loaded">
            <div class="detail-header">
                <div class="header-title">Le moyen de paiement n'a pas encore été enregistré</div>
            </div>
        </div>
        <div class="payment-detail" v-if="mode=='view' && loaded">
            <div class="detail-header">
                <div class="header-title">Moyen de paiement enregistré</div>
            </div>
            <div class="detail-content">
                <div class="payment-sepa" v-if="residentPayment.type == 'sepa_debit'">
                    <div class="sepa-header">
                        <img class="sepa-icon" src="@/assets/images/ehpad/sepa.svg" />
                        <div class="sepa-title">
                            <b>Prélèvement SEPA</b>
                        </div>
                    </div>
                    <div class="sepa-content">
                        <div class="sepa-name">{{ residentPayment.name }}</div>
                        <div class="sepa-number">{{ residentPayment.bankCode }}*************{{ residentPayment.last4 }}</div>
                    </div>
                </div>
                <div class="payment-card" v-if="residentPayment.type == 'card'">
                    <div class="card-header">
                        <img class="card-icon" src="@/assets/images/ehpad/card.svg" />
                        <div class="card-title">
                                <b>Prélèvement CB</b>
                        </div>
                    </div>
                    <div class="card-content">
                        <div class="card-name">{{ residentPayment.name }}</div>
                        <div class="card-number">**** **** **** {{ residentPayment.last4 }}</div>
                        <div class="card-number">Valide jusque : {{ residentPayment.expirationMonth }}/{{ residentPayment.expirationYear }}</div>
                    </div>
                </div>
                <div class="form-actions" v-if="$aclChecker.some($acl, ['isTutor', 'isFamily', 'isHappytalSupport'])">
                    <vs-button
                        class="update-btn"
                        @click="onUpdateBtnClick"
                        data-cy="update-payment-btn"
                    >Mettre à jour</vs-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import async from 'async'

import Http from '@/http'

import Routes from '@/constants/routes'
import PaymentModeSelector from '@/components/selectors/PaymentModeSelector'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import { HBtn } from '@happytal/bo-ui-library'
import appsettings from '@/appsettings'
import Oauth from '@/oauth'

export default {
    props: [
        'resident_id'
    ],
    components: {
        ResidentHeader,
        PaymentModeSelector,
        HBtn
    },
    data () {
        return {
            addNewDataSidebar: false,
            sidebarData: {},
            selected: Routes.ResidentPayment,
            residentPayment: null,
            payment: {
                secret: null,
                mode: {
                    setup: null,
                },
                name: null,
                email: null,
                iban: null,
                bic: null,
                card: null,
                cgv_consent: false,
                save_consent: false
            },
            iban: null,
            ibanValid: false,
            card:null,
            cardValid: false,
            fieldErrors : {},
            saveBtnDisabled: true,
            mode: 'view',
            loaded: false,
            stripeLoaded: false
        }
    },
    computed: {
         nextMonth () {
        return getNextMonth()
        },
        isUserFamily () {
            return this.$aclChecker.some(this.$acl, [
                'isFamily'
            ])
        },
        isUserTutor () {
            return this.$aclChecker.some(this.$acl, [
                'isTutor'
            ])
        },
        linkToResidentProfil() {
            return {
                name: Routes.ResidentProfile
            }
        }
    },
    methods: {
        checkFields () {
            switch (this.payment.mode.setup) {
                case 'sepa_debit':
                    if (!this.ibanValid) {
                        this.saveBtnDisabled = true
                        return
                    }
                    break
                case 'card':
                    if (!this.cardValid) {
                        this.saveBtnDisabled = true
                        return
                    }
                    break
            }
            this.saveBtnDisabled = this.errors.has('email') || !this.payment.name || !this.payment.email || !this.payment.save_consent //|| !this.payment.data_consent
        },
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        onPaymentSetupModeChange (mode) {
            this.payment.mode.setup = mode
        },
        getResident () {
            return this.getResidentById(this.resident_id)
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getResidentName () {
            return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
        },
        getResidentPaymentData (residentId) {
            const resident = this.getResidentById(residentId)
            if (resident && resident.stripePaymentMethod) {
                return resident.stripePaymentMethod
            }
            return null
        },
        getName () {
            const stripeData = this.getResidentPaymentData(this.resident_id)
            return stripeData ? stripeData.name : '-'
        },
        getEmail() {
            const stripeData = this.getResidentPaymentData(this.resident_id)
            return stripeData ? stripeData.email : '-'
        },
        getIban () {
            const stripeData = this.getResidentPaymentData(this.resident_id)
            return stripeData ? stripeData.bankCode + '*************' + stripeData.last4 : '-'
        },
        getCard () {
            const stripeData = this.getResidentPaymentData(this.resident_id)
            return stripeData ? '*************' + stripeData.last4 : '-'
        },
        onUpdateBtnClick(e) {
            this.$vs.loading()
            this.mode = 'update'
            this.payment.name = this.getResidentName(this.resident_id)
            this.payment.mode.setup = this.isUserTutor ? 'sepa_debit' : 'card'
            setTimeout(() => {
                this.initStripe(true, true)
                this.stripeLoaded = true
            },1000)
        },
        onSaveBtnClick (e) {
            this.$vs.loading()
            Http.apis.ehpad.get(`/residents/${this.resident_id}/payment-method-setup-intent`)
            .then(
                (response) => {
                    this.payment.secret = response.data.setupIntentClientSecret
                    switch (this.payment.mode.setup) {
                        case 'sepa_debit':
                            this.stripe.confirmSepaDebitSetup(this.payment.secret, {
                                payment_method: {
                                    sepa_debit: this.iban,
                                    billing_details: {
                                        name: this.payment.name,
                                        email: this.payment.email
                                    }
                                }
                            })
                            .then(this.onStripeSetup.bind(this))
                            break
                        case 'card':
                            this.stripe.confirmCardSetup(this.payment.secret, {
                                payment_method: {
                                    card: this.card,
                                    billing_details: {
                                        name: this.payment.name,
                                        email: this.payment.email
                                    }
                                }
                            })
                            .then(this.onStripeSetup.bind(this))
                            break
                    }
                },
                (error) => {
                    this.$vs.loading.close()
                    this.showErrorToast(this.getGeneralErrorMessage(error))
                }
            )
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        onStripeSetup (result) {

            if (result.error) {
                const message = _.get(result, 'error.message', '')
                this.showErrorToast(`Une erreur Stripe s'est produite (${message}).`)
                this.$vs.loading.close()
                return
            }
            Http.apis.ehpad.post(`/residents/${this.resident_id}/payment-method`, {
                stripePaymentMethodId: result.setupIntent.payment_method
            }).then((response) => {
                const payment = _.get(response, 'data.stripePaymentMethod', null)
                this.$store.commit('dataResidents/UPDATE_PAYMENT', {
                    residentId: this.resident_id,
                    payment: payment
                })
                this.$vs.loading.close()
                this.showInfoToast(`Moyen de paiement enregistré.`)
                this.residentPayment = this.getResidentPaymentData(this.resident_id)
                this.mode = 'view'
                this.saveBtnDisabled = true
            }).catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        initStripe (iban, card) {
            this.stripe = Stripe(appsettings.VUE_APP_STRIPE_ID)
            var elements = this.stripe.elements()
            var style = {
                base: {
                    color: '#32325d',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    },
                    ':-webkit-autofill': {
                        color: '#32325d'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                    ':-webkit-autofill': {
                        color: '#fa755a'
                    }
                }
            }
            if (iban) {
                this.iban = elements.create('iban', {
                    style: style,
                    supportedCountries: ['SEPA'],
                    placeholderCountry: 'FR'
                })
                this.iban.mount('.iban-element')
                this.iban.on('change', ({error}) => {
                    this.ibanValid = error ? false : true
                    this.checkFields()
                })
                this.iban.on('ready', event => {
                    this.stripeLoaded = true
                    this.$vs.loading.close()
                });
            }
            if (card) {
                this.card = elements.create('card', {
                    style,
                    hidePostalCode: true,
                })
                this.card.mount('.card-element')
                this.card.on('change', ({error}) => {
                    this.cardValid = error ? false : true
                    this.checkFields()
                })
                this.card.on('ready',event => {
                    this.stripeLoaded = true
                    this.$vs.loading.close()
                });
            }
        },
          showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: 5000,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    watch: {
        'payment.name' (val) {
            this.checkFields()
        },
        'payment.email' (val) {
            this.checkFields()
        },
        'payment.cgv_consent' (val) {
            this.checkFields()
        },
        'payment.save_consent' (val) {
            this.checkFields()
        }
    },
    async mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        }).then(() => {

                            callback(null)
                        })
                        return
                    }
                    callback(null)
                },
                (callback) => {
                    if (!this.$store.state.dataTutors.isRegistered) {
                        this.$store.dispatch('dataTutors/getTutors', {
                            institutionId: this.getInstitutionId()
                        }).then(() => {
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
              Oauth.checkUserRight(this.$store.getters['dataResidents/getResidentById'](this.resident_id), this.$router, this.$vs.loading)
                this.residentPayment = this.getResidentPaymentData(this.resident_id)
                if (!this.residentPayment) {

                    this.mode = 'update'
                    if (this.$aclChecker.some(this.$acl, ['isTutor', 'isFamily', 'isHappytalSupport']))
                    {
                        this.$vs.loading()
                        this.payment.name = this.getResidentName(this.resident_id)
                        this.payment.mode.setup = this.isUserTutor ? 'sepa_debit' : 'card'
                        setTimeout(() => {
                            this.initStripe(true,true)
                            this.stripeLoaded=true
                        },1000)
                    }

                }
                else {
                    this.mode = 'view'
                }
                this.loaded = true
            }
        )
    }
}
</script>

<style lang="scss">
#resident-detail-view-moyen-paiement {

    .form-check {
        display: flex;

       .check-input {
            display:flex;
              .vs-checkbox {
            flex-shrink: 0;
            margin-right: 10px;
            }
        }
    }
    .mt-5 {
        margin-top: 1.5rem !important;
    }
    .small {
        font-size: 12px;
        font-weight: normal;
    }
    .strong {
        font-weight: bold;
    }
    .vs-con-input-label {
        width: 98%;
    }
    .con-vs-alert-warning {
        background-color: #e5f6f8;
        color: #00CFE8;
        font-weight: normal;
    }
    .vs-alert {
        display: block;
    }
    .con-vs-alert-warning h4 {
        -webkit-box-shadow: 0 6px 15px -7px rgba(#00CFE8,.4);
        box-shadow: 0 6px 15px -7px rgba(#00CFE8,.4);
    }
    #iban-element {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        padding:7px !important;
        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: 0 0 0 0 rgba(0,0,0,.15);
        box-sizing: border-box;
        width: 98%;
    }
    #card-element {
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        padding:7px !important;
        border-radius: 5px;
        box-sizing: border-box;
        box-shadow: 0 0 0 0 rgba(0,0,0,.15);
        box-sizing: border-box;
        width: 98%;
    }
    .cgv {
        .mt-11 {
            margin-top: 1px;
        }
    }
    .invalid input {
        border-color: red !important;
    }
    .cgvcolored .vs-checkbox-primary input:checked+.vs-checkbox {
        border: 2px solid grey!important;
        background: grey;
    }

    .payment-title {
        margin: 0px 0px 26px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #2C2C2C;
    }

    .payment-form {
        padding: 0px 24px 24px 24px;

        .form-label {
            margin: 20px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .form-input {
            margin: 5px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .stripe-form-input {
            margin: 5px 0px 0px 0px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 7px;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .form-field-error {

        }
        .input-name {

            .vs-con-input {
                width: 460px;
            }
        }
        .input-email {

            .vs-con-input {
                width: 460px;
            }
        }
        .input-iban {
            width: 647px;
        }
        .input-bic {

            .vs-con-input {
                width: 460px;
            }
        }
        .input-card {
            width: 460px;
        }
        .form-actions {
            text-align: right;

            .save-btn {
                width: 273px;
                margin: 18px 0px 0px 0px;
            }
        }
    }

    .payment-sepa {
        padding: 22px 25px 22px 25px;

        .sepa-header {
            display: flex;
            align-items: center;

            .sepa-radio {
                margin: 0px 16px 0px 0px;
            }
            .sepa-icon {

            }
            .sepa-title {
                margin: 0px 0px 0px 16px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #2C2C2C;
            }
        }
        .sepa-content {
            display: flex;
            margin: 14px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #979797;

            .sepa-name {
                flex-grow: 1;
                text-align: center;
            }
            .sepa-number {
                flex-grow: 1;
                text-align: center;
            }
            .sepa-bic {
                flex-grow: 1;
                text-align: center;
            }
        }
        .sepa-legend {
            margin: 20px 0px 10px 0px;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #2C2C2C;
        }
    }

    .payment-card {
        padding: 22px 25px 22px 25px;

        .card-header {
            display: flex;
            align-items: center;

            .card-radio {
                margin: 0px 16px 0px 0px;
            }
            .card-icon {

            }
            .card-title {
                margin: 0px 0px 0px 16px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #2C2C2C;
            }
        }
        .card-content {
            display: flex;
            margin: 14px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #979797;

            .card-name {
                flex-grow: 1;
                text-align: center;
            }
            .card-number {
                flex-grow: 1;
                text-align: center;
            }
        }
        .card-legend {
            margin: 20px 0px 10px 0px;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #2C2C2C;
        }
    }

    .payment-detail {
        margin: 0px 0px 42px 0px;
        border: 2px solid #D8D8D8;
        border-radius: 8px;
        font-family: Montserrat;
        color: #2C2C2C;

        .detail-header {
            display: flex;
            align-items: center;
            padding: 22px 22px 22px 22px;

            .header-title {
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
            .header-price {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: right;
            }
            .header-price-subscription {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 13px;
                text-align: right;
            }
            .header-price-detail {
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
            }
        }
        .detail-content:last-child {
            margin: 0px 0px 0px 0px;
        }
        .detail-content {
            margin: 0px 0px 37px 0px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
            border-radius: 8px;
            background: white;

            .payment-label {
                padding: 10px 0px 10px 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
            }
            .payment-selector {
                margin: 0px 24px 0px 24px;
            }
            .payment-selector {

            }
            .payment-custom {
                padding: 10px 0px 10px 0px;
                border-bottom: 1px solid #D8D8D8;

                .label {
                    max-width: 568px;
                    margin: 0px auto 0px auto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .form-actions {
                text-align: right;
                .update-btn{
                    width: 273px;
                    margin: 18px 20px 18px 0px;
                }
            }
        }

    }
}
</style>
